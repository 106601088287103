//
#PaylineWidget,
.PaylineWidget {
  .pl-pmContainer {
    border: none;
    background: none;
    margin: auto;
  }

  .pl-icon-warning {
    top: 3px !important;
  }

  .pl-form-control,
  .pl-form-container {
    .pl-input {
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
    }

    .pl-form-control-addon {
      display: none;
    }
  }

  .pl-form-group {
    margin-bottom: 10px;
  }

  .pl-card-logos-container {
    border-radius: 0;
    border: 0;
    background: inherit;
    padding-bottom: 10px;
  }

  .pl-label-input {
    color: @grey-1;
    font-size: 13px;
  }

  .pl-message-error {
    font-size: 13px;
  }

  * {
    font-family: inherit !important;
  }

  .pl-input-group-container {
    border: 1px solid #d5dce7;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    color: #445871;
    outline: none;
    // padding: 0.5px;
    width: 100%;

    .input {
      padding: 4px 16px 8px 12px !important;
    }
  }

  .pl-has-error {
    .pl-input-group-container {
      border: 1px solid @red;
      background: white;
    }
  }

  .pl-expirationDateContainer,
  .pl-cvvContainer {
    display: block;
    float: left;
    margin: 0;
  }

  .pl-expirationDateContainer {
    width: 49%;
    margin-right: 1%;
  }

  .pl-cvvContainer {
    width: 49%;
    margin-left: 1%;
  }

  .pl-pay-btn {
    height: 3.4rem;
    margin-top: 15px;
    border-radius: 0.4rem;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-weight: @bold;
    color: white;
    background-color: @violet !important;

    &:disabled,
    &[disabled] {
      background-color: #d5dce7;
    }
  }
}

// overide background
.PaylineWidget
  .pl-container-view
  .pl-cardNumberContainer.pl-has-error
  .pl-input {
  background: transparent;
}

// override expiration  background
.PaylineWidget .pl-expirationDateContainer.pl-has-error .pl-input {
  background: transparent !important;
}

// override cvv background
.PaylineWidget .pl-container-view .pl-cvvContainer.pl-has-error .pl-input {
  background: transparent;
}

// error text
.PaylineWidget .pl-message-error,
.PaylineWidget .pl-message-warn {
  color: @red !important;
}

.pl-input[id^="pl-pm-cards_"] {
  padding: 5px 20px 5px 10px !important;
  height: 37px !important;
}

// all inputs (card number / exp date / cvv)
#pl-pm-cards_4-styleOfIframe-cvv,
#pl-pm-cards_4-styleOfIframe-cardNumber {
  padding: 0 20px 0 10px;
  height: 37px !important;
  min-height: 37px !important;
}

// all inputs
.PaylineWidget .pl-paymentMethodLayout-view .pl-pmContainer .pl-input {
  margin: 0 !important;
}

.PaylineWidget .pl-input-lg,
.PaylineWidget .pl-paymentMethodLayout-view .pl-pmContainer .pl-input {
  height: 45px !important;

  &:not(input) {
    padding: 10px 0px !important;
  }
}

// iframe cvv
#pl-pm-cards_4-cvv {
  margin: 0;
  padding: 10px 0px;
}
