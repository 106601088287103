@import "./reset.less";
@import "./fonts.less";

@import "./components/button.less";
@import "./components/loader.less";
@import "./components/payline.less";

@import "./pages/form.less";
@import "./pages/error.less";

@violet: #0b69b3;
@grey-1: #30325d;
@grey-2: #445871;
@grey-3: #bfc5ce;
@grey-4: #f8f8fe;
@grey-5: #f1f1f9;
@green-1: #12eaa9;
@red: #ef003b;

@bold: 700;
@semibold: 600;
@normal: 400;

body {
  background: @grey-4;
  margin: 0;

  color: @grey-2;

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .app-content {
    flex-grow: 1;
  }
}
