.container {
  max-width: 700px;
  margin: auto;

  text-align: center;

  padding: 1rem 0;

  @media screen and (max-width: 800px) {
    padding: 1rem 30px;
  }
}

.error {
  display: inline-block;
  color: @red;
}

.success {
  display: inline-block;
  color: @green-1!important;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.bg-grey-5 {
  background: @grey-5;
}

.bg-white {
  background: white;
}

.logo {
  margin: 20px auto;

  img {
    display: inline-block;
  }
}

h1 {
  margin: 40px 0 0 0;
  font-size: 22px;
}

.payment-meta {
  border-top: 1px solid @grey-3;

  margin-top: 30px;
  padding: 30px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.info-box {
  flex-grow: 1;

  h3 {
    font-size: 12px;
    text-align: left;
    margin-bottom: 4px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 2px;

    border-bottom: 2px solid @red;
    box-shadow: 0px 4px 16px -9px rgba(0, 21, 100, 0.3);
  }

  span {
    flex-grow: 1;
    display: inline-block;
    padding: 10px;
    font-weight: @bold;
    color: @grey-1;
    font-size: 22px;

    + span {
      border-left: 1px solid @grey-5;
    }
  }

  + .info-box {
    margin-left: 20px;

    @media screen and (max-width: 530px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.payment-downloads {
  text-align: left;
  color: @violet;
  font-weight: @bold;
  font-size: 13px;

  li {
    &:before {
      content: " ";
      width: 9px;
      height: 12px;
      margin-right: 5px;

      display: inline-block;
      vertical-align: middle;
      background-image: url("../img/arrow-to-bottom.png");
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:hover {
      text-decoration: underline;
    }

    + li {
      margin-top: 7px;
    }
  }
}

.box-main {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 6px;

  margin: 40px 0;

  .box-logo {
    display: inline-block;
    margin-bottom: 10px;
  }

  .box-copy {
    display: block;
    margin: 20px 0;

    border-radius: 4px;
    background: @grey-4;
    padding: 10px;
  }

  h2 {
    color: @grey-1;
    margin-top: 10px;
  }
}

.payment-box {
  min-height: 400px;

  .loader {
    top: unset;
    bottom: 0;
  }

  .error {
    margin-top: 30px;
  }
}

.payment-explanation {
  text-align: left;
  margin-top: 25px;

  h3 {
    margin-bottom: 20px;
    font-size: 12px;
  }

  span {
    display: block;
  }

  h4 {
    font-size: 10px;
    margin-bottom: 5px;

    ~ span {
      font-size: 14px;
    }
  }

  p {
    margin-bottom: 15px;
  }

  .agency {
    margin: 20px 0;
  }
}

.payment-confirmation {
  display: flex;
  flex-wrap: wrap;

  text-align: left;
  margin: 20px 0;

  > div {
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 1;

    margin: 10px 0;
    font-weight: @bold;

    h4 {
      font-size: 12px;
    }

    span {
      color: @grey-1;
      font-size: 22px;

      sup {
        font-size: 8px;
        vertical-align: text-top;
      }
    }
  }
}

footer {
  padding: 40px 0;
  text-align: center;

  a {
    margin-right: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
}
